import React, {useState, useEffect} from "react"
import axios from "axios"
import { navigate } from "gatsby"
import clearbit from "utils/clearbitAxios"
import styled from "styled-components"
import useSignUp from "./customHooks"
import { isSSR } from "utils/"

let redirectHost = 'hrflow.ai'
if(!isSSR) {
  const host =  window.location.host;
  redirectHost =
  host.indexOf('localhost') > -1
    ? 'rimdev.net'
    : host.indexOf('rimdev') > -1 || host.indexOf('rimpreprod') > -1 ? 'rimdev.net' : 'hrflow.ai';
}


const StyledCard = styled.div`
  width: 340px;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.06);
  z-index: 1;
  padding: 40px;
  width: 100%;
  h2 {
    color: #3c3c3c;
    font-size: 30px;
    font-weight: 600;
    line-height: 43px;
    margin-bottom: 1rem;
    text-align: center;
  }
`

const StyledForm = styled.form`
  border: none;
  width: 100%;
  margin: 1rem 0;
  .control {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    .label {
      font-size: 13px;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.9rem;
      letter-spacing: -.015rem;
      color: #3c3c3c;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 10px;
      margin-top: 0 !important;
    }
    .input {
      width: 100%;
      display: block;
      font-size: 0.9rem;
      line-height: 1.3;
      color: #495057;
      background-color: #fff !important;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 4px;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
      box-shadow: none;
      padding: 13px 20px;
    }
    .input-inline {
      display: flex;
      width: 100%;
      align-items: center;
      .input {
        width: -webkit-fill-available;
        border-top-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-right: none;
      }
      .app-domain {
        font-size: 0.9rem;
        line-height: 1.3;
        padding: 13px 20px 13px 0;
        color: #ccc;
        font-weight: 600;
        border: 1px solid #ced4da;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
      }
    }
  }

  .button {
    width: 100%;
    margin-top: 1rem;
    padding: 13px;
    background-color: #00cee0;
    border-width: 0;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    transition: background 0.3s;
    font-size: 1.5rem;
  }

  .terms {
    margin-top: 1rem;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 13px;
    color: #999;
    text-align: center;
  }
`
const StyledError =  styled.div`
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 40px 0px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  color: white;
  letter-spacing: 0.5px;
  background: #ff5a6e;
  padding: 14px;
`
const buildQueryString =  (url, queryObject) => {
  let queryString = `${url}?`;
  Object.keys(queryObject).forEach(item => {
    if (typeof queryObject[item] === 'string' || queryObject[item] instanceof String) {
      queryString += `${item}=${queryObject[item]}&`;
    } else {
      queryString += `${item}=${JSON.stringify(queryObject[item])}&`;
    }
  });
  return queryString; 
}

const Signup = () => {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const register = () => {
    const email = inputs.email
    let subdomain = inputs.subdomain
    const password = inputs.password
    let teamName = ""
    let firstName = ""
    let lastName = ""
    const splitedEmail = email.split('@')[0]
    setError("")
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    subdomain = subdomain.toLowerCase();
    if(!re.test(email)) {
      setError("Please provide a valid work email!")
    } else if (password.length < 7) {
      setError("Your password is too short.")
    } else if (!subdomain.match(/^[a-z0-9][a-z0-9\-]*[a-z0-9]$/)) {
      setError(`'Invalid workspace name: it must not contain a special caracter, only "-" is allowed'`)
    } else {
      setError("")
    }

    if(error.length === 0) {
      setIsLoading(true)
      var clearbitData = {};
      clearbit.get(email).then(res => {
        clearbitData = res;
      
        if (clearbitData.company  && clearbitData.company.name) {
          teamName = clearbitData.company.name;
        } else {
          teamName = subdomain;
        }
        if (clearbitData.person  && clearbitData.person.name && clearbitData.person.name.familyName) {
          lastName = clearbitData.person.name.familyName;
        } else {
          lastName = splitedEmail.split('.')[1] || "";
        }
        if(clearbitData.person  && clearbitData.person.name && clearbitData.person.name.givenName) {
          firstName = clearbitData.person.name.givenName;
        } else {
          firstName = splitedEmail.split('.')[0] || ""; 
        }
        let pseudo = firstName.toLowerCase()+lastName.toLowerCase();
        return {email: email, password: password, psuedo: pseudo, firstName: firstName, lastName: lastName || firstName, clearbitCompany: clearbitData.company, clearbitPerson: clearbitData.person };
      }).then(result => {
        axios.post(`https://api-dashboard.${redirectHost}/sf/api/public/user/signup`, result)
        .then(res => {
          const token = res.data.token
          window.analytics.track("SIGNUP_PERSONAL_SUCCESS", {
            email: email,
            firstName: firstName,
            lastName: lastName
          });
          var  TeamData = {name: teamName, subdomain: subdomain };
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          axios.post(`https://api-dashboard.${redirectHost}/sf/api/team`, TeamData).then(res => {
            window.analytics.track("SIGNUP_COMPANY_SUCCESS", {
              teamName: teamName,
              subdomain: subdomain
            });
            const clearbitPerson = clearbitData && clearbitData.person;
            const clearbitCompany = clearbitData && clearbitData.company;
            let pseudo = firstName.toLowerCase()+lastName.toLowerCase();
            const zapierData = {
              firstName: firstName,
              lastName: lastName || firstName,
              teamName: teamName,
              email: encodeURIComponent(email),
              subdomain: subdomain,
              pseudo: pseudo,
              fullName: firstName + ' ' + lastName,
              phone: '',
              companyName: clearbitCompany && clearbitCompany.name || '',
              employmentTitle: clearbitPerson && clearbitPerson.employment.title || '',
              employmentSeniority: clearbitPerson && clearbitPerson.employment.seniority || '',
              linkedinHandle: clearbitPerson && clearbitPerson.linkedin.handle || '',
              twitterHandle: clearbitPerson && clearbitPerson.twitter.handle || '',
              country: clearbitPerson && clearbitPerson.gro.country || '',
              gender: '',
              location: clearbitPerson && clearbitPerson.location || '',
            }

            analytics.identify(email, {
              ...zapierData
            });
            setIsLoading(false)
            const zapierUrl = buildQueryString("https://hooks.zapier.com/hooks/catch/4274830/okca2gr", zapierData);
            axios.post(zapierUrl, zapierData)
            .then(() => {
              var redirectUrl = "https://" + subdomain + "." + `${redirectHost}/signin/auto?code=` + res.data.token;
              navigate(redirectUrl)
            }).catch(err => {
              var redirectUrl = "https://" + subdomain + "." + `${redirectHost}/signin/auto?code=` + res.data.token;
              navigate(redirectUrl)
            });
          }).catch(err => {
            window.analytics.track("SIGNUP_COMPANY_FAIL", {
              teamName: name,
              subdomain: subdomain
            });
            setIsLoading(false)
            if(err.response.status === 409) {
              setError("Subdomain already existe")
            }
          })
        }).catch(err => {
          window.analytics.track("SIGNUP_PERSONAL_FAIL", {
            email: email,
            firstName: firstName,
            lastName: lastName
          });
          setIsLoading(false)
          if(err.response.status === 409) {
            setError("It seems you already have an account.")
          } else {
            setError("Something went wrong please try again!")
          }
        })
      }).catch(err => {
        teamName = subdomain;
        lastName = splitedEmail.split('.')[1] || "";
        firstName = splitedEmail.split('.')[0] || "";
        let pseudo = firstName.toLowerCase()+lastName.toLowerCase();
        const data = {email: email, password: password, psuedo: pseudo, firstName: firstName, lastName: lastName || firstName, clearbitCompany: {}, clearbitPerson: {} };
      
        axios.post(`https://api-dashboard.${redirectHost}/sf/api/public/user/signup`, data)
        .then(res => {
          const token = res.data.token
          window.analytics.track("SIGNUP_PERSONAL_SUCCESS", {
            email: email,
            firstName: firstName,
            lastName: lastName
          });
          var  TeamData = {name: teamName, subdomain: subdomain };
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          axios.post(`https://api-dashboard.${redirectHost}/sf/api/team`, TeamData).then(res => {
            window.analytics.track("SIGNUP_COMPANY_SUCCESS", {
              teamName: teamName,
              subdomain: subdomain
            });
            const zapierData = {
              firstName: firstName,
              lastName: lastName || firstName,
              teamName: teamName,
              email: encodeURIComponent(email),
              subdomain: subdomain,
              pseudo: pseudo,
            }
            analytics.identify(email, {
              ...zapierData
            });
            const zapierUrl = buildQueryString("https://hooks.zapier.com/hooks/catch/4274830/okca2gr", zapierData);
            axios.post(zapierUrl, zapierData)
            .then(res => {
              var redirectUrl = "https://" + subdomain + "." + `${redirectHost}/signin/auto?code=` + res.data.token;
              navigate(redirectUrl)
            }).catch(err => {
              var redirectUrl = "https://" + subdomain + "." + `${redirectHost}/signin/auto?code=` + res.data.token;
              navigate(redirectUrl)
            });
            setIsLoading(false)
          }).catch(err => {
            window.analytics.track("SIGNUP_COMPANY_FAIL", {
              teamName: name,
              subdomain: subdomain
            });
            if(err.response.status === 409) {
              setError("Subdomain already existe")
            }
            setIsLoading(false)
          })
        }).catch(err => {
          window.analytics.track("SIGNUP_PERSONAL_FAIL", {
            email: email,
            firstName: firstName,
            lastName: lastName
          });
          if(err.response.status === 409) {
            setError("It seems you already have an account.")
          } else {
            setError("It seems your email is invalid.")
          }
          setIsLoading(false)
        })
      });
    }
  }
  const { inputs, handleInputChange, handleSubmit } = useSignUp(register);
  return (
    <>
      {error.length > 0 ? (   <StyledError>{error}</StyledError>): ''}
      <StyledCard>
        <h2>Get Started</h2>
        <StyledForm  onSubmit={handleSubmit} id="signup-form">
          <div className="control">
            <div className="label">Work Email *</div>
            <input
              type="text"
              className="input"
              name="email"
              placeholder="elon.musk@teslamotors.com"
              onChange={handleInputChange}
              value={inputs.email}
              required
            />
          </div>
          <div className="control">
            <div className="label">Workspace (Subdomain) *</div>
            <div className="input-inline">
              <input
                type="text"
                className="input"
                name="subdomain"
                placeholder="teslamotors"
                onChange={handleInputChange}
                value={inputs.subdomain}
                required
              />
              <span className="app-domain">.hrflow.ai</span>
            </div>
          </div>
          <div className="control">
            <div className="label">Password *</div>
            <input
              type="password"
              className="input"
              name="password"
              placeholder="********"
              onChange={handleInputChange}
              value={inputs.password}
              required
            />
          </div>
          <button type="submit" className="button">{isLoading ? <span className="loader"></span> : "Create Account"}</button>
          <div className="terms">
              By signing up, I agree to the <a href="https://help.hrflow.ai/en/articles/4418910-privacy-policy">Terms of Service</a> <br />
              and <a href="https://help.hrflow.ai/en/articles/4418910-privacy-policy">Privacy policy</a>.
          </div>
        </StyledForm>
      </StyledCard>
    </>
  );
}

export default Signup